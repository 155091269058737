/* HomePage.css */

/* Horizontal scrolling container */
.scrolling-wrapper {
  position: relative; /* Set position to relative for absolute positioning of arrows */
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px; /* Add padding for spacing */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
  margin: 20px 10px 10px 30px; /* Adjust margins */
}

/* Hide scrollbar for Webkit browsers */
.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}
.trending
{
  padding-top: 20px;
  text-align: center;
  color: rgb(198, 62, 164);
}
.container-fluid {
  background-image: url('../../public/images/bestsellerbackground.jpg');
  color: #fff;
  font-family: 'system-ui';
}

/* Individual product card */
.product-card {
  display: inline-block;
  margin-right: 25px; /* Add margin for spacing between cards */
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 20px;
  width: 220px; /* Set the width */
  height: 250px; /* Set the height to match the width */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative; /* Add relative positioning */
}

/* Logo/cart icon */
.logo-cart {
  font-size: 24px;
  color: #333;
  position: absolute; /* Position the icon */
  top: 10px; /* Adjust top position */
  right: 10px; /* Adjust right position */
}

/* Main product image */
.main-images {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden; /* Hide overflow to prevent image stretching */
}

.main-images img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
  object-position: center; /* Center the image vertically */
}

/* Hover effect */
.product-card:hover {
  transform: translateY(-5px); /* Move the card up slightly on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 768px) {
  .scrolling-wrapper {
    padding: 15px 5px; /* Reduce padding for smaller screens */
    margin: 20px 5px 10px 15px; /* Adjust margins for smaller screens */
  }

  .product-card {
    width: 180px; /* Reduce width for smaller screens */
    height: 200px; /* Adjust height accordingly */
    margin-right: 15px; /* Reduce margin for spacing */
    padding: 15px; /* Adjust padding */
  }

  .logo-cart {
    font-size: 20px; /* Adjust icon size */
  }
}

@media (max-width: 576px) {
  .scrolling-wrapper {
    padding: 10px 2px; /* Further reduce padding for very small screens */
    margin: 15px 2px 10px 10px; /* Adjust margins for very small screens */
  }

  .product-card {
    width: 130px; /* Further reduce width for very small screens */
    height:140px; /* Adjust height accordingly */
    margin-right: 10px; /* Reduce margin for spacing */
    padding: 10px; /* Adjust padding */
  }

  .logo-cart {
    font-size: 18px; /* Further adjust icon size */
  }
}
.banner-img {
  width: 100%;
  height: auto;
}

.container-fluid {
  margin-bottom: 20px; /* Adjust the margin as needed */
}
@media (max-width: 768px) {
  .banner-img {
    height: auto; /* Ensure the height adjusts dynamically */
  }
}
