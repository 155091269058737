.background {
  background-image: url('../../public/images/bestsellerbackground.jpg');
  margin:10px 0px;
  background-size: cover;
  background-position: center;
  height: 100vh; /* Set height to 100% of viewport height */
  width: 100%;
  padding-top: 10px; /* Add padding to the top instead of margin-top */
  display: flex;
  flex-direction: column; /* Ensure vertical stacking */
  align-items: center; /* Center horizontally */
  overflow: auto; /* Enable vertical scrolling if needed */

}

.bestseller {
  padding-top: 20px;
  text-align: center;
  color: rgb(225, 78, 188);
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.product-card-container {
  flex: 0 0 auto;
  width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.product-card-container img {
  max-width: 100%;
  height: auto;
}

/* Add the rest of your CSS styles here */

.bestseller {
  padding-top: 20px;
  text-align: center;
  color: rgb(198, 62, 164);
}

.product-card-container {
  position: relative;
  max-width: 280px;
  width: 100%;
  border-radius: 15px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.logo-cart-container img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.main-images-container {
  height: 160px;
}

.product-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.shoe-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shoe-details-container .shoe-name {
  font-size: 24px;
  font-weight: 500;
  color: #161616;
  margin-top: 10px;
  text-align: center;
}

.shoe-details-container p {
  font-size: 12px;
  font-weight: 400;
  color: #333;
  text-align: justify;
}

.color-price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.color-price-container .price-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.color-price-container .price-container .price-number {
  font-size: 25px;
  font-weight: 600;
  color: #707070;
}

.color-price-container .price-container .price-letter {
  font-size: 10px;
  font-weight: 600;
  margin-top: -4px;
  color: #707070;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-container button {
  flex: 1;
  height: 50px;
  margin-right: 10px;
  border: none;
  outline: none;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
}

.button-container button:hover {
  background-color: #555;
}


@media screen and (max-width: 768px) {
  /* Adjust styles for smaller screens */
  .background {
    height: 85vh; /* Adjust the height as needed */
  }
  .product-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px 0; /* Add some padding */
    width: 100%; /* Ensure full width */
    justify-content: flex-start; /* Start from the beginning */
  }

  .product-card-container {
    flex: 0 0 auto;
    width: 100%; /* Take full width on smaller screens */
    max-width: 280px; /* Limit maximum width */
    margin-right: 10px;
    margin-bottom: 20px;
    
  }

  .background {
    overflow: initial; /* Reset overflow property */
  }
}
.ultimate
{
  color: white;
  font-size: 12px;
}
.product-card-container img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* Add transition for smooth effect */
}

.product-card-container img:hover {
  transform: scale(1.1); /* Scale the image to 110% on hover */
}
