/* Original styles */
/* Google Fonts Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center horizontally by default */
  align-items: center; /* Center vertically by default */
  padding: 20px; /* Add padding to create space between product cards */
}

.product-card {
  position: relative;
  max-width: 355px;
  width: 100%;
  border-radius: 25px;
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 10px; /* Adjust margin for space between product cards */
}

.product-card .logo-cart img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.product-card .main-images {
  height: 310px;
}

.product-card .main-images img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.product-card .shoe-details {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center vertically */
}

.product-card .shoe-details .shoe_name {
  font-size: 24px;
  font-weight: 500;
  color: #161616;
  margin-top: 10px;
  text-align: center; /* Center horizontally */
}

.product-card .shoe-details p {
  font-size: 12px;
  font-weight: 400;
  color: #333;
  text-align: justify;
}

.color-price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.color-price .price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.color-price .price .price_num {
  font-size: 25px;
  font-weight: 600;
  color: #707070;
}

.color-price .price .price_letter {
  font-size: 10px;
  font-weight: 600;
  margin-top: -4px;
  color: #707070;
}

.product-card .button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.product-card .button-container button {
  flex: 1;
  height: 50px;
  margin-right: 10px;
  border: none;
  outline: none;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
}

.product-card .button-container button:hover {
  background-color: #555;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .product-card {
    max-width: calc(50% - 20px);
    margin-left: -40px;
  }
}

@media screen and (max-width: 576px) {
  .product-card {
    max-width: calc(100% - 20px);
  }
}
