/* FAQ.css */

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.faq-title {
  margin-bottom: 2rem;
}

.faq-section {
  width: 100%;
  max-width: 800px;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgb(231, 227, 227)!important;
}
.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgb(232, 226, 225) !important;
}


.back-to-home {
  padding: 0.5rem 2rem;
  font-size: 1.2rem;
}


