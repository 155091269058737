.bnbtt
{
    margin: 10px;

}
.custom-navlink {
    color: inherit; /* Use the default text color */
    text-decoration: none; /* Remove underline */
  }

  .description-container {
    display: block;
    white-space: pre-wrap; /* This property will wrap the text onto the next line */
    word-wrap: break-word; /* Ensure long words are broken into the next line */
  }
  