/* TermsAndConditions.module.css */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.heading {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.section {
    margin-bottom: 40px;
}

.section h3 {
    color: #555;
    font-size: 20px;
    margin-bottom: 10px;
}

.section p {
    color: #666;
    font-size: 16px;
    line-height: 1.6;
}
