/* About.module.css */
.about-page {
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  color: #343a40;
  padding: 40px 0;
}

.hero-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #495057;
  color: #ffffff;
  border-radius: 8px;
  margin-bottom: 40px;
}

.hero-section h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 24px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

h2 {
  font-size: 32px;
  color: #495057;
  margin-bottom: 20px;
}

h3 {
  font-size: 24px;
  color: #495057;
  margin-top: 20px;
}

p {
  font-size: 18px;
  line-height: 1.6;
  color: #6c757d;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border: none;
  border-radius: 8px;
}
