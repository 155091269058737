.navbar-dark .nav-item .nav-link {
    color: #fff;
  }
  
  .navbar-dark .nav-item .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    border-radius: 0.25rem;
    color: #fff;
  }
  
  .fa-li {
    position: relative;
    left: 0;
  }
  /* .active{
    border-bottom: 10px solid white;
  } */
  .custom-dropdown-menu {
    .dropdown-item {
      color: black;
    }
  }
  