/* Input field with floating label */
.form-outline {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .form-outline input {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border: 2px solid transparent; /* Transparent border by default */
    border-radius: 4px;
    transition: border-color 0.2s;
  }
  
  /* Style the label */
  .form-outline label {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    background-color: white;
    padding: 0 0.25rem;
    color: #999;
    transition: top 0.2s, font-size 0.2s, color 0.2s;
    pointer-events: none; /* Prevent label from being clickable */
  }
  
  /* Keep label on top if input has value */
  .form-outline input.active + label {
    top: -0.5rem;
    font-size: 0.75rem;
    color: #333;
  }
  
  /* Add border color when input is focused */
  .form-outline input:focus {
    outline: none;
    border-color: #007bff;
  }
  