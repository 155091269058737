body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
html{
  width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.product-link {
  text-decoration: none !important;
  color: black !important;
}
.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  background-color: #fff;
}

.card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ccc;
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.card-text {
  color: #666;
  margin-bottom: 15px;
  font-size: 0.9em;
}

.button-container {
  display: flex;
  align-items: center;
}

.btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #45a049;
}

.button-container > *:not(:last-child) {
  margin-right: 10px;
}
.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 30px;
}

.product-card {
  /* Your existing styles for the product card */
  margin: 10px; /* Add margin for spacing between products */
}